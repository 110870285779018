<template>
  <div class="pa-3">
    <v-dialog v-model="dialogEmail" persistent width="35vw">
      <v-card>
        <v-card-title>Envio de e-mail com anexo!</v-card-title>
        <v-card-subtitle>Premiação área 4</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-space-between align-center">
            <v-text-field
              :readonly="aguardarEnvio"
              v-model="emailEnvio"
              label="Digite o(s) email(s) para enviar"
              dense
            ></v-text-field>
            <v-progress-circular
              class="ml-3"
              v-if="aguardarEnvio"
              :width="3"
              color="green"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :disabled="aguardarEnvio"
            class="error"
            @click="dialogEmail = false"
            x-small
            >Fechar</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="aguardarEnvio"
            class="success"
            @click="handleEnviaEmail"
            x-small
            >Enviar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="overlay" persistent width="20vw">
      <v-card>
        <v-card-title>Buscando dados.</v-card-title>
        <v-card-subtitle>Aguarde...</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="salvaDados" persistent width="20vw">
      <v-card>
        <v-card-title>Salvando dados.</v-card-title>
        <v-card-subtitle>Aguarde...</v-card-subtitle>
        <v-card-text>
          <div class="d-flex justify-center align-center">
            <v-progress-circular
              :size="25"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <div class="d-flex justify-center align-center">
        <v-row no-gutters>
          <v-col cols="1" class="ma-2">
            <v-autocomplete
              dense
              v-model="anoEscolhido"
              :items="anos"
              :loading="loadingAnos"
              small-chips
              label="Escolha o Ano"
            ></v-autocomplete>
          </v-col>
          <v-col cols="2" class="ma-2">
            <v-autocomplete
              dense
              v-model="mesEscolhido"
              :items="meses"
              item-text="mes"
              item-value="value"
              small-chips
              label="Escolha o mês"
            ></v-autocomplete>
          </v-col>
          <div class="d-flex justify-center align-center ml-6">
            <v-btn class="primary" small @click="carregaDados('N')"
              >Carregar Dados</v-btn
            >
          </div>
        </v-row>
      </div>
      <v-data-table
        dense
        :headers="headersComiss"
        :items="dadosTabela"
        class="elevation-1 tbCamp"
        height="83vh"
        :items-per-page="dadosTabela.length"
        fixed-header
        no-data-text="Sem dados no momento"
        :loading="overlay"
        loading-text="Buscando dados aguarde..."
        hide-default-footer
      >
        <template v-slot:top>
          <div
            class="d-flex justify-space-around align-center"
            v-if="dadosTabela.length > 0"
          >
            <v-badge color="grey darken-2" :content="dadosTabela.length">
              <div class="d-flex flex-column align-center">
                <span class="font-weight-bold grey--text text--darken-2"
                  >Total de registros</span
                >
              </div>
            </v-badge>
            <v-badge color="red darken-2" :content="semPorcentagem">
              <div
                class="d-flex flex-column align-center hand"
                @click="carregaDados('S')"
              >
                <span class="font-weight-bold red--text text--darken-2"
                  >Com faturamento e sem porcentagem de comissão</span
                >
              </div>
            </v-badge>
          </div>
        </template>

        <template v-slot:[`item.COMISSAO`]="{ item }">
          <div
            v-if="item.CANAL === 'PARTICULAR'"
            class="d-flex justify-end align-center pa-2"
          >
            {{ item.COMISSAO }}
          </div>
          <div v-else class="d-flex justify-end align-center pa-2">
            {{ item.COMISSAO }}
          </div>
        </template>

        <template v-slot:[`item.CANAL`]="{ item }">
          <v-chip
            class="ma-2"
            x-small
            color="blue darken-2"
            dark
            v-if="item.CANAL === 'INDICAÇÃO'"
          >
            <span class="font-weight-bold">{{ item.CANAL }}</span>
          </v-chip>

          <v-chip
            class="ma-2"
            x-small
            color="green darken-2"
            dark
            v-if="item.CANAL === 'PARTICULAR'"
          >
            <span class="font-weight-bold">{{ item.CANAL }}</span>
          </v-chip>
        </template>

        <template v-slot:[`item.Action`]="{ item }">
          <div class="d-flex justify-space-around align-center">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="blue darken-2"
                  class="pa-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="handleGerarPdf(item)"
                >
                  <v-icon>mdi-file-pdf-box</v-icon>
                </v-btn>
              </template>
              <span>Gerar PDF</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  icon
                  color="red darken-2"
                  class="pa-5"
                  v-bind="attrs"
                  v-on="on"
                  @click="chamaDialogEmail(item)"
                >
                  <v-icon>mdi-email-fast</v-icon>
                </v-btn>
              </template>
              <span>Enviar E-mail</span>
            </v-tooltip>
          </div>
        </template>
      </v-data-table>
    </v-container>
  </div>
</template>
<script>
import axios from "axios";
import { add, sub } from "date-fns";

import dinero from "dinero.js";

export default {
  name: "fonosRepre",
  data: () => {
    return {
      dialogEmail: false,
      aguardarEnvio: false,
      emailEnvio: null,
      loadingAnos: false,
      anos: [],
      semPorcentagem: 0,
      meses: [
        { mes: "Janeiro", value: "0131" },
        { mes: "Fevereiro", value: "0229" },
        { mes: "Março", value: "0331" },
        { mes: "Abril", value: "0430" },
        { mes: "Maio", value: "0531" },
        { mes: "Junho", value: "0630" },
        { mes: "Julho", value: "0731" },
        { mes: "Agosto", value: "0831" },
        { mes: "Setembro", value: "0930" },
        { mes: "Outubro", value: "1031" },
        { mes: "Novembro", value: "1130" },
        { mes: "Dezembro", value: "1231" },
      ],
      overlay: false,
      mesEscolhido: "",
      anoEscolhido: "",
      salvaDados: false,
      dadosTabela: [],
      itemEmail: null,
      headersComiss: [
        {
          text: "Código",
          value: "Codigo",
          align: "start",

          caption: "title",
        },
        {
          text: "Ações",
          value: "Action",
          align: "center",
        },
        {
          text: "Fono parceiro",
          value: "Nome",
          align: "start",

          caption: "title",
        },
        {
          text: "Canal",
          value: "CANAL",
          align: "start",

          caption: "title",
        },
        {
          text: "Vendido",
          value: "FATURAMENTO",
          align: "end",

          caption: "title",
        },
        {
          text: "% Comissão",
          value: "COMISSAO",
          align: "end",

          caption: "title",
        },
        {
          text: "Comissão à pagar",
          value: "VALOR",
          align: "end",

          caption: "title",
        },
      ],
    };
  },
  methods: {
    async chamaDialogEmail(item) {
      this.emailEnvio = "";
      this.emailEnvio = item.email.trim();
      this.dialogEmail = true;
      this.itemEmail = item;
    },
    async handleEnviaEmail() {
      this.overlay = true;
      const dados = {
        vendedor: this.itemEmail.Codigo,
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/getComissFonosParcAnalitico`;

      const faturamento = await axios.post(url, dados);

      if (!faturamento && faturamento.status !== 200) {
        console.log("erro ao buscar faturamento");
      }

      let totalPremio = faturamento.data.reduce(
        (total, item) => total + item.REPASSE,
        0,
      );

      totalPremio = Number((totalPremio * 100).toFixed(2).replace(".", ""));

      faturamento.data.map(e => {
        e.FATURAMENTO = dinero({
          amount: e.FATURAMENTO * 100,
          currency: "BRL",
        })
          .setLocale("pt-BR")
          .toFormat();
        e.REPASSE = dinero({
          amount: e.REPASSE * 100,
          currency: "BRL",
        })
          .setLocale("pt-BR")
          .toFormat();
        e.PORC = dinero({
          amount: e.PORC * 100,
        }).toFormat("0.00");
      });

      totalPremio = dinero({
        amount: totalPremio / 100,
        currency: "BRL",
      })
        .setLocale("pt-BR")
        .toFormat();

      const dadosEnvio = {
        nome: this.itemEmail.Nome.trim(),
        tabelaPagamentos: faturamento.data,
        totalPremio,
        titulo: `Repasse.`,
        email: this.emailEnvio,
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      };

      const urlEnvio = `${process.env.VUE_APP_BASE_API_URL}/premioA4/enviaEmail`;

      const envio = await axios.post(urlEnvio, dadosEnvio);

      if (envio.status !== 200) {
        console.log("Erro ao enviar e-mail");
      } else {
        this.dialogEmail = false;
        this.emailEnvio = null;
      }

      this.overlay = false;
    },
    async buscaAnos() {
      const anosSub = sub(new Date(), { years: 2 });

      for (let cont = 0; cont <= 2; cont++) {
        if (
          add(anosSub, { years: cont }).getFullYear() <=
          new Date().getFullYear()
        ) {
          this.anos.push(add(anosSub, { years: cont }).getFullYear());
        }
      }

      this.anos = this.anos.reverse();
      this.loadingAnos = false;
    },
    async carregaDados(tipo) {
      this.overlay = true;
      this.dadosTabela = [];
      this.semPorcentagem = 0;

      const url = `${process.env.VUE_APP_BASE_API_URL}/getComissFonos`;

      const dados = await axios.post(url, {
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      });

      this.overlay = false;

      if (!dados || dados.status !== 200) {
        console.log("erro");
      }

      dados.data.map(e => {
        const roundVal = Math.round((e.Faturamento ?? 0) * 100)
        if (
          e.Faturamento > 0 &&
          e.COMISSAO_INDICACAO > 0 &&
          e.CANAL === "INDICAÇÃO"
        ) {
          const valor = Number(
            dinero({ amount: roundVal})
              .percentage(e.COMISSAO_INDICACAO)
              .getAmount(),
          );
          e.VALOR = dinero({
            amount: valor,
            currency: "BRL",
          })
            .setLocale("pt-BR")
            .toFormat();
          e.COMISSAO = dinero({
            amount: e.COMISSAO_INDICACAO * 100,
          }).toFormat("0.00");
        } else if (
          e.Faturamento > 0 &&
          e.COMISSAO_PARTICULAR > 0 &&
          e.CANAL === "PARTICULAR"
        ) {
          const valor = Number(
            dinero({ amount: roundVal })
              .percentage(e.COMISSAO_PARTICULAR)
              .getAmount(),
          );
          e.VALOR = dinero({
            amount: valor,
            currency: "BRL",
          })
            .setLocale("pt-BR")
            .toFormat();
          e.COMISSAO = dinero({
            amount: e.COMISSAO_PARTICULAR * 100,
          }).toFormat("0.00");
        } else {
          e.VALOR = dinero({
            amount: 0,
            currency: "BRL",
          })
            .setLocale("pt-BR")
            .toFormat();
          e.COMISSAO = dinero({
            amount: 0 * 100,
          }).toFormat("0.00");
        }
        e.FATURAMENTO = dinero({
          amount: roundVal,
          currency: "BRL",
        })
          .setLocale("pt-BR")
          .toFormat();
      });

      if (tipo === "S") {
        this.dadosTabela = dados.data.filter(
          e => e.Comissao <= 0 && e.Faturamento > 0,
        );
      } else {
        this.dadosTabela = dados.data;
      }

      this.semPorcentagem = dados.data.filter(
        e => e.Comissao <= 0 && e.Faturamento > 0,
      ).length;
    },
    async calculaComissao(item) {
      const percent = Number(item.COMISSAO.replace(",", "."));

      item.valor = Number(
        dinero({ amount: item.Faturamento * 100 })
          .percentage(percent)
          .getAmount(),
      );

      item.VALOR = dinero({
        amount: item.valor,
        currency: "BRL",
      })
        .setLocale("pt-BR")
        .toFormat();

      item.Comissao = Number(item.COMISSAO.replace(",", "."));

      if (item.Comissao <= 0 && !item.Recno) return;

      this.salvaDados = true;

      const url = `${process.env.VUE_APP_BASE_API_URL}/persistiDados`;

      const result = await axios.post(url, {
        item,
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      });

      if (!result || result.status !== 200) {
        console.log("Erro");
      }

      this.semPorcentagem = this.dadosTabela.filter(
        e => e.Comissao <= 0 && e.Faturamento > 0,
      ).length;

      this.salvaDados = false;
    },
    async handleGerarPdf(item) {
      this.overlay = true;
      const dados = {
        vendedor: item.Codigo,
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      };

      const url = `${process.env.VUE_APP_BASE_API_URL}/getComissFonosParcAnalitico`;

      const faturamento = await axios.post(url, dados);

      if (!faturamento && faturamento.status !== 200) {
        console.log("erro ao buscar faturamento");
      }

      let totalPremio = faturamento.data.reduce(
        (total, item) => total + item.REPASSE,
        0,
      );

      totalPremio = Number((totalPremio * 100).toFixed(2).replace(".", ""));

      faturamento.data.map(e => {
        e.FATURAMENTO = dinero({
          amount: e.FATURAMENTO * 100,
          currency: "BRL",
        })
          .setLocale("pt-BR")
          .toFormat();
        e.REPASSE = dinero({
          amount: e.REPASSE * 100,
          currency: "BRL",
        })
          .setLocale("pt-BR")
          .toFormat();
        e.PORC = dinero({
          amount: e.PORC * 100,
        }).toFormat("0.00");
      });

      totalPremio = dinero({
        amount: totalPremio / 100,
        currency: "BRL",
      })
        .setLocale("pt-BR")
        .toFormat();

      const dadosEnvio = {
        nome: item.Nome,
        tabelaPagamentos: faturamento.data,
        totalPremio,
        ano: this.anoEscolhido,
        mes: this.mesEscolhido,
      };

      const urlPdf = `${process.env.VUE_APP_BASE_API_URL}/premioA4/pdf`;

      try {
        const res = await axios.post(urlPdf, dadosEnvio, {
          responseType: "blob",
        });
        const browserTab = window.open(URL.createObjectURL(res.data), "_blank");

        if (browserTab) {
          browserTab.focus();
        } else {
          this.error = "pop-up do arquivo PDF bloqueada pelo navegador";
          this.snackbar_error = true;
        }
      } catch (error) {
        this.error = error;
      }

      this.overlay = false;
    },
  },
  mounted() {
    this.buscaAnos();

    // this.buscaVendedores();
  },
};
</script>
<style>
.v-input input {
  font-size: 1.2rem;
}
.hand :hover {
  cursor: pointer;
}
</style>
